/*
    WEBSITE TRAVELER REDESIGN
    created on: 01/09/2023
    Author: ISC. Oswaldo Álvarez Juárez
    Email: oswaldo.aj29@outlook.com

*/

'use strict';

/*===============================================
VARIABLES GENERALES
=================================================*/

const loginPartner = document.querySelector('#loginForm');
const btnSubmit = document.querySelector('#submitLogin');
const eyeShowPass = document.querySelector('#showPass');


$(document).ready(function(){
    StartEvents();
    sizeFix();
});

function sizeFix(){
  $('.nav-link').on('shown.bs.tab', function (e) {
    var activeTabId = $(e.target).attr('id');
    if (activeTabId === 'tab1' && $(e.target).hasClass('active')) {
        $('#login-form').css('max-width', '50rem');
        //$('#mainLogin').css('height', '80vh');
    } else if (activeTabId === 'tab2' && $(e.target).hasClass('active')) {
        $('#login-form').css('max-width', '80rem');
        //$('#mainLogin').css('height', '200vh');
    }
  });
}


//FUNCTION THAT ENABLE THE EVENTS
function StartEvents(){
  btnSubmit.addEventListener('click', validaFormPartner);
  eyeShowPass.addEventListener('click', showPass);
}

// FUNCTIONS
/**
 * author: ISC. OSWALDO ALVAREZ JUAREZ
 * email: oswaldo.aj29@outlook.com
 * description: Evento que muestra y oculta la contraseña
 */
function showPass(e){
  e.preventDefault();
  const eyeShowPass = document.querySelector('#showPass');
  let password = document.querySelector('#password');
  if(password.type === 'password'){
    password.type = 'text';
    eyeShowPass.querySelector('#eyeIcon').classList.remove('fa-eye');
    eyeShowPass.querySelector('#eyeIcon').classList.add('fa-eye-slash');
  }else{
    password.type = 'password';
    eyeShowPass.querySelector('#eyeIcon').classList.add('fa-eye');
    eyeShowPass.querySelector('#eyeIcon').classList.remove('fa-eye-slash');
  }
}

/**
 * Evento que se activa al querer iniciar sesión en la ruta partner/login
 * @param {object DOM} e -este es el elemento del evento 
 */
function validaFormPartner(e){
  e.preventDefault();
  let username = document.querySelector('#username').value;
  let pass = document.querySelector('#password').value.length;
  let terms = document.querySelector('#conditionscheck').checked;
  let evalUser = false;
  let evalPass = false;
  let evalTerms = false;
  let expr = /[0-9]{1,9}/;


  if(!username.match(expr)){
    document.querySelector("#errorUser").innerText = "La membresia solo puede incluir números";
    document.querySelector("#errorUser").classList.remove('hidden');

  }else{
    evalUser=true;
    $("#errorUser").text('');
    $("#errorUser").addClass('hidden');
  }

  if(pass == 0 || pass == undefined){
    $("#errorPass").text("La contraseña no puede estar vacia");
    $("#errorPass").removeClass("hidden");
  }else{
    evalPass = true;
    $("#errorPass").text('');
    $("#errorPass").addClass('hidden');
  }

  if(terms == null || terms == "" || terms == undefined){
    $("#errorTerms").text('Acepte términos y condiciones');
    $("#errorTerms").removeClass('hidden');
  }else{
    evalTerms = true;
    $("#errorTerms").text('');
    $("#errorTerms").addClass('hidden');
  }

  if(evalUser && evalPass && evalTerms){
      $('<input>').attr('type', 'hidden').attr('name', '_coords').attr('value', localStorage.getItem('lat')+'|'+localStorage.getItem('lng')).appendTo('#loginForm');
      loginPartner.submit();
  }
}




